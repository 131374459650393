<template>
  <div id="event_index" class="register">
    <main class="pd-b-0">
      <div class="title_wrap">
        <div class="w1100 container">
          <div class="title">
            <h3 class="txt-bold">國內學術活動</h3>
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
          <div id="courses_filter">
            <div class="filter">
              <div class="select_box">
                <small class="select_title txt-bold">積分類型</small>
                <select
                  name="point_type"
                  id="point_type"
                  class="p"
                  v-model="params.type_id"
                  @change="filterData()"
                >
                  <option value="">全部</option>
                  <option
                    v-for="fraction in fractionTypeOptions"
                    :key="fraction.id"
                    :value="fraction.name.toLowerCase()"
                  >{{ fraction.name }}</option>
                </select>
              </div>
              <div class="select_box">
                <small class="select_title txt-bold">活動類型</small>
                <select
                  name="activity_type"
                  id="activity_type"
                  class="p"
                  v-model="params.host_type"
                  @change="filterData()"
                >
                  <option value="">全部</option>
                  <option
                    v-for="(hostType, index) in hostTypeOptions"
                    :key="hostType.id"
                    :value="index + 1"
                  >{{ hostType.name }}</option>
                </select>
              </div>
              <div class="input-group">
                <label for="keyword" class="small txt-bold">搜尋活動主題</label>
                <div class="input-wrap">
                  <input
                    type="text"
                    name="keyword"
                    id="keyword"
                    placeholder="標題"
                    class="p"
                    v-model.trim="params.keyword"
                    @keyup.enter="filterData()"
                  >
                  <button type="button" class="p" @click="filterData()">查詢</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="w1100">
          <ul class="event_list pd-b-0">
            <li v-for="cardItem in cardArray" :key="cardItem.uuid">
              <router-link
                :to="`/register/event/info?event_id=${cardItem.uuid}`"
                class="cards_item"
              >
                <div class="wrap">
                  <div class="col20">
                    <div class="data_wrap">
                      <h4 class="ym">
                        {{ startYear(cardItem) }} . {{ startMonth(cardItem) }}
                      </h4>
                      <h1 class="d">{{ startDay(cardItem) }}</h1>
                    </div>
                  </div>
                  <div class="col80">
                    <p class="card_title">{{ cardItem.name }}</p>
                    <p class="card_host small">主辦單位：{{ cardItem.host }}</p>
                    <p class="card_fraction small type-A" v-if="cardItem.fraction_a">
                      <span class="tag">A類</span>
                      <span class="credit">．{{ cardItem.fraction_a }}學分</span>
                    </p>
                    <p class="card_fraction small type-B" v-if="cardItem.fraction_b">
                      <span class="tag">B類</span>
                      <span class="credit">．{{ cardItem.fraction_b }}學分</span>
                    </p>
                    <p class="location ls1">{{ cardItem.place }}</p>
                    <!-- <p class="time ls1">{{ cardItem.time }}</p> -->
                  </div>
                </div>
              </router-link>
            </li>
          </ul>

          <Pagination
            :pageButton="pageButton"
            :page="params.page"
            :totalPage="totalPage"
            @updatePage="updatePage"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { eventsIndex } from '@/lib/http';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'RegisterEventIndex',
  data() {
    return {
      /** Static Data */
      pageButton: 5,
      componentKey: 0,

      /** Search Params */
      params: {
        type_id: '',
        host_type: '',
        keyword: '',
        limit: 10,
        page: 1,
      },

      /** Axios Data */
      totalPage: 1,
      cardArray: [
        // {
        //   id: 1,
        //   year: '2020',
        //   month: '08',
        //   day: '21',
        //   title: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員 教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練',
        //   host: '台灣居家醫療醫學會',
        //   tag: 'A',
        //   credits: '3',
        //   location: '中山醫學大學，正心樓0225教室',
        //   time: '10:00 - 16:00 (6 hours)',
        // },
        // {
        //   id: 2,
        //   year: '2020',
        //   month: '08',
        //   day: '21',
        //   title: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員 教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練教育訓練',
        //   host: '台灣居家醫療醫學會',
        //   tag: 'B',
        //   credits: '3',
        //   location: '中山醫學大學，正心樓0225教室',
        //   time: '10:00 - 16:00 (6 hours)',
        // },
      ],
    };
  },
  created() {
    if (this.$route.query.type_id) {
      this.params.type_id = this.$route.query.type_id;
    }
    if (this.$route.query.host_type) {
      this.params.host_type = this.$route.query.host_type;
    }
    if (this.$route.query.keyword) {
      this.params.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.num) {
      this.params.page = parseInt(this.$route.query.num, 10);
    }
    this.filterData(this.params.page);
  },
  computed: {
    ...mapGetters([
      'activityType',
      'hostType',
    ]),
    fractionTypeOptions() {
      if (this.activityType !== []) {
        return this.activityType.filter((item) => item.name === 'A' || item.name === 'B');
      }
      return [];
    },
    hostTypeOptions() {
      if (this.hostType !== []) {
        return this.hostType;
      }
      return [];
    },
  },
  methods: {
    startYear(data) {
      const startDate = _.get(data, 'start_time', '');
      if (startDate) {
        return moment(startDate).format('YYYY');
      }
      return '';
    },
    startMonth(data) {
      const startDate = _.get(data, 'start_time', '');
      if (startDate) {
        return moment(startDate).format('MM');
      }
      return '';
    },
    startDay(data) {
      const startDate = _.get(data, 'start_time', '');
      if (startDate) {
        return moment(startDate).format('DD');
      }
      return '';
    },
    /**
     * API Call：Event Index
     */
    filterData(pageValue = 1) {
      eventsIndex({
        ...this.params,
        page: pageValue,
      }).then((result) => {
        if (Object.keys(result).length > 0) {
          this.totalPage = result.pageCount;
          this.cardArray = result.data;
          this.params.page = pageValue;
          this.$router.push({
            query: {
              type_id: this.params.type_id,
              host_type: this.params.host_type,
              keyword: this.params.keyword,
              num: pageValue,
            },
          });
        }
      });
    },
    /**
     * 積分類型及活動類型ID篩選
     */
    // filterById() {
    //   if (this.type_id === 'all') {
    //     this.filterData(this.params);
    //   } else {
    //     this.filterData({ ...this.params, type_id: this.type_id });
    //   }
    // },
    /**
     * 分頁器：換頁功能
     */
    updatePage(value) {
      // this.params.page = value;
      // this.$router.push({ query: { num: value } });
      this.filterData(value);
    },
  },
  watch: {
    /**
     * 偵測user使用網頁的上一頁功能時，重設頁數及重call API
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // console.log('from', from);
      // console.log('to', to);
      const queryNum = parseInt(this.$route.query.num, 10);
      if (queryNum && queryNum > 0) {
        this.params.page = parseInt(this.$route.query.num, 10);
        this.filterData(this.params.page);
      }
    },
  },
  components: {
    Pagination,
  },
};
</script>
